
import {defineComponent} from 'vue';
import $ from "jquery";
import svgPanZoom from 'svg-pan-zoom';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import Card from "@/components/elements/Card.vue";
import {ScreensEnum} from "@/Screens.enum";

const TOGGLE_COLOR = 'rgb(200, 117, 54)';
const HOVER_COLOR = 'rgb(100, 143, 210)';
const OK_PATH = 'path.st11';
// const HOVER_COLOR = '#648fd2';

export default defineComponent({
  name: 'MapScreen',
  components: {ScreenWrapper, Card},
  props: {
    msg: String,
    goTo: Function,
  },
  data() {
    return {
      screen: ScreensEnum.MAP,
      ok: null,
      lastSelected: null
    };
  },

  mounted() {
    setTimeout(() => {
      this.$root.setupAndPlayIfOn(this.FILES.audio_map)
    }, 1000)
  },

  methods: {
    initMap() {
      const map = this.$refs.map as HTMLElement;
      const mapDocument = getContentDocument(map);

      console.log(map, mapDocument)

      svgPanZoom(map, {
        panEnabled: true
        , controlIconsEnabled: true
        , zoomEnabled: true
        , dblClickZoomEnabled: false
        , mouseWheelZoomEnabled: false
        , preventMouseEventsDefault: false
        , zoomScaleSensitivity: 0.2
        , minZoom: 0.7
        , maxZoom: 2
        , fit: true
        , contain: false
        , center: true
        , refreshRate: 'auto'
      });
      if (mapDocument) {
        const paths = $(mapDocument).find('path[class]');
        paths.on('click', this.toggleSelected);
        paths.on('mouseover', (e) => this.toggleHover(e, true));
        paths.on('mouseout', (e) => this.toggleHover(e, false));

        paths.css('cursor', 'pointer');
      }
    },
    toggleSelected(event: Event) {
      if (this.lastSelected) {
        this.restoreColor(this.lastSelected)
      }

      const path = $(event.target as HTMLElement)
      this.setOriginalColorAttr(path)

      if (path.data('selected')) {
        this.restoreColor(path)
        path.data('selected', false)
        this.lastSelected = null;
      } else {
        path.css('fill', TOGGLE_COLOR)
        path.data('selected', true)
        this.lastSelected = path;
      }
    },

    setOriginalColorAttr(path) {
      let originalFill = path.attr('data-fill');

      console.log(path, originalFill, TOGGLE_COLOR)

      if (!originalFill) {
        originalFill = path.css('fill')
        path.attr('data-fill', originalFill)
      }

      return originalFill;
    },

    toggleHover(event: MouseEvent, on: boolean) {
      const path = $(event.target as HTMLElement)
      this.setOriginalColorAttr(path)

      if (path.data('selected')) {
        return;
      }

      if (on) {
        path.css('fill', HOVER_COLOR)
      } else {
        this.restoreColor(path)
      }
    },

    restoreColor(pathEl: HTMLElement) {
      const path = $(pathEl);
      const originalFill = path.attr('data-fill');

      if (originalFill) {
        path.css('fill', originalFill)
      }
    },

    next() {
      this.goTo(ScreensEnum.ALTERNATIVE_SUPPLY)
    },

    submit() {
      this.ok = this.lastSelected && $(this.lastSelected).is(OK_PATH);

      if (this.ok) {
        this.$root.setupAndPlayIfOn(this.FILES.audio_map_correct)
      } else {
        this.$root.setupAndPlayIfOn(this.FILES.audio_map_wrong)
      }
    }
  }
});

// get the embedded document, if possible
function getContentDocument(embeddingElement: HTMLEmbedElement | HTMLObjectElement | HTMLElement) {
  if ((embeddingElement as HTMLObjectElement).contentDocument) {
    return (embeddingElement as HTMLObjectElement).contentDocument;
  }
  try {
    return (embeddingElement as HTMLEmbedElement).getSVGDocument();
  } catch (e) {
    console.error(e)
  }
  return null;
}
