
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import Card from "@/components/elements/Card.vue";
import ReadMore from "@/components/elements/ReadMore.vue";
import {ScreensEnum} from "@/Screens.enum";

export default defineComponent({
  name: 'MagnetsStructureScreen',
  components: {
    ReadMore,
    ScreenWrapper, Card
  },
  props: {
    msg: String,
    goTo: Function
  },

  data() {
    return {
      screen: ScreensEnum.MAGNETS_STRUCTURE,
    }
  },

  mounted() {
    setTimeout(() => {
      this.$root.setupAndPlayIfOn(this.FILES.audio_structure)
    }, 1000)
  },

  methods: {
    next() {
      this.goTo(ScreensEnum.MAGNETS_PROCESS)
    }
  }
});
