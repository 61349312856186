
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {ScreensEnum} from "@/Screens.enum";
import axios from "axios";

function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
  }
  return result;
}

export default defineComponent({
  name: 'AlmostEndScreen',
  components: {
    ScreenWrapper,
  },
  props: {
    msg: String,
    goTo: Function,
  },

  data() {
    return {
      screen: ScreensEnum.FEEDBACK,
      q1: null,
      q2: null,
      sent: false,
      hash: makeid(10)
    }
  },

  mounted() {
    let existing = null;
    try {
      existing = localStorage.getItem('almost-end')
    } catch (e) {
      console.warn(e)
    }

    if (existing) {
      try {
        const ans = JSON.parse(existing);
        this.q1 = ans.q1;
        this.q2 = ans.q2;
        this.hash = ans.hash || makeid(10);
      } catch (e) {
        console.error(e);
      }
    }

    setTimeout(() => {
      this.$root.setupAndPlayIfOn(this.FILES.audio_almostend)
    }, 1000)

  },

  computed: {
    isDone() {
      return this.q1 && this.q2
    }
  },

  methods: {
    next() {
      this.goTo(ScreensEnum.ACTION_TIME)
    },
    submit() {

      try {
        localStorage.setItem('almost-end', JSON.stringify({
          q1: this.q1, q2: this.q2, hash: this.hash,
        }))
      } catch (e) {
        console.warn(e)
      }

      if (this.isDone) {
        // const authParts = (process.env.VUE_APP_SUBMIT_API_AUTH || '').split(":");
        // const auth = authParts?.length > 1 ? {username: authParts[0], password: authParts[1]} : null;

        axios.post(process.env.VUE_APP_SUBMIT_API, {
          question1: this.$t('almost-end.question1'),
          answer1: this.q1,
          question2: this.$t('almost-end.question2'),
          answer2: this.q2,
          hash: this.hash,
        }, {}).then(() => {
          try {
            // Save only hash for overriding answer
            localStorage.setItem('almost-end', JSON.stringify({
              hash: this.hash,
            }))
          } catch (e) {
            console.warn(e)
          }

          this.sent = true;
          this.next();
        }).catch(console.error)
      } else {
        alert('Select answers before submitting')
      }
    }
  }
});
