
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {ScreensEnum} from "@/Screens.enum";

export default defineComponent({
  name: 'NowWhatScreen',
  components: {
    ScreenWrapper
  },
  props: {
    msg: String,
    goTo: Function
  },

  data() {
    return {
      screen: ScreensEnum.ACTION_TIME,
    }
  },

  mounted() {
    setTimeout(() => {
      this.$root.setupAndPlayIfOn(this.FILES.audio_whatcanyou)
    }, 1000)
  },

  methods: {
    next() {
      this.goTo(ScreensEnum.WELCOME)
    }
  }
});
