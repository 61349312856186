
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {ScreensEnum} from "@/Screens.enum";
import Card from "@/components/elements/Card.vue";
import ReadMore from "@/components/elements/ReadMore.vue";

export default defineComponent({
  name: 'AlternativeSupplyScreen',
  components: {
    ScreenWrapper,
    ReadMore,
    Card,
  },
  props: {
    msg: String,
    goTo: Function
  },

  data() {
    return {
      screen: ScreensEnum.GET_MAGNETS,
      showCard: 0,
      isModalShown: false
    }
  },

  methods: {
    next() {
      this.goTo(ScreensEnum.MAGNETS_STRUCTURE)
    },

    selectCard(card) {
      this.showCard = card;

      switch (card) {
        case 1:
          this.$root.setupAndPlayIfOn(this.FILES.audio_get_smart)
          break;
        case 2:
          this.$root.setupAndPlayIfOn(this.FILES.audio_get_smart_didyouknow)
          break;
        case 3:
          this.$root.setupAndPlayIfOn(this.FILES.audio_get_wise)
          break;
        case 4:
          this.$root.setupAndPlayIfOn(this.FILES.audio_get_wise_didyouknow)
          break;
      }

    },
    closeModal() {
      this.isModalShown = false;
    },
    openModal() {
      this.isModalShown = true;
    }
  }
});
