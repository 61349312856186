
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'TransitionWrapper',
  props: {
    index: String,
    currentScreen: String,
    back: Boolean,
    screens: Object,
  },
  data() {
    return {
      display: false
    }
  },

  mounted() {
    if (this.isActive) {
      this.display = true;
    }
  },
  computed: {
    isActive() {
      return this.$props.index === this.$root.currentScreen;
    },
  },
  watch: {},
  methods: {
    updateVisibility() {
      if (this.isActive) {
        this.display = true;
        // this.$refs.TransitionWrapper.scrollTo({
        //   top: 0
        // })
      } else {
        setTimeout(() => {
          if (!this.isActive) {
            // Check again
            this.display = false;
          }
        }, 3000)
      }
    }
  }
});
