
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'ReadMore',
  emits: ['open', 'close'],
  props: {
    title: String,
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggle() {
      this.show = !this.show

      this.$emit(this.show ? 'open' : 'close');
    }
  }
});
