import {createApp} from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import $ from "jquery";
import VueNextSelect from 'vue-next-select'
import i18n from './i18n'
import VueUniversalModal from 'vue-universal-modal'
import VueMatomo from 'vue-matomo'
import './style.scss'
import 'vue-universal-modal/dist/index.css'
import {Integrations} from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import CountryFlag from 'vue-country-flag-next'
import {localeFilesMixin} from "@/mixins/localeFilesMixin";


const app = createApp(App)
// @ts-ignore
window.$ = $;
// @ts-ignore
window.app = app;

if (process.env.NODE_ENV === 'production') {
    console.log('Sentry init');
    Sentry.init({
        app,
        dsn: "https://7bd2947f1de6467695db670dfff12f66@o405761.ingest.sentry.io/6052464",
        integrations: [
            new Integrations.BrowserTracing({
                // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["localhost", "discoverees.prospex-institute.org", /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        beforeSend: (event, hint) => {
            console.error(hint.originalException, hint.syntheticException, event);

            return event;
        }
    });
}

// app.mixin(audioPlayerMixin)

app.use(i18n)
app.use(VueUniversalModal, {
    teleportTarget: '#modals'
})
app.component('VueSelect', VueNextSelect)
app.component('CountryFlag', CountryFlag)
app.mixin(localeFilesMixin)

app.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: 'https://analytics.prospex-institute.org',
    siteId: 1,

    // Changes the default .js and .php endpoint's filename
    // Default: 'matomo'
    // trackerFileName: 'matomo',

    // Overrides the autogenerated tracker endpoint entirely
    // Default: undefined
    // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

    // Overrides the autogenerated tracker script path entirely
    // Default: undefined
    // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

    // Enables automatically registering pageviews on the router
    // router: router,

    // Enables link tracking on regular links. Note that this won't
    // work for routing links (ie. internal Vue router links)
    // Default: true
    enableLinkTracking: true,

    // Require consent before sending tracking information to matomo
    // Default: false
    requireConsent: false,

    // Whether to track the initial page view
    // Default: true
    trackInitialView: true,

    // Run Matomo without cookies
    // Default: false
    disableCookies: true,

    // Require consent before creating matomo session cookie
    // Default: false
    requireCookieConsent: false,

    // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
    // Default: false
    enableHeartBeatTimer: true,

    // Set the heartbeat timer interval
    // Default: 15
    heartBeatTimerInterval: 60,

    // Whether or not to log debug information
    // Default: false
    debug: true,

    // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
    // Default: undefined
    userId: undefined,

    // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
    // Default: undefined, example '*.example.com'
    cookieDomain: undefined,

    // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
    // Default: undefined, example: '*.example.com'
    domains: undefined,

    // A list of pre-initialization actions that run before matomo is loaded
    // Default: []
    // Example: [
    //   ['API_method_name', parameter_list],
    //   ['setCustomVariable','1','VisitorType','Member'],
    //   ['appendToTrackingUrl', 'new_visit=1'],
    //   etc.
    // ]
    preInitActions: [],

    // A function to determine whether to track an interaction as a site search
    // instead of as a page view. If not a function, all interactions will be
    // tracked as page views. Receives the new route as an argument, and
    // returns either an object of keyword, category (optional) and resultsCount
    // (optional) to track as a site search, or a falsey value to track as a page
    // view.
    // Default: false, i.e. track all interactions as page views
    // Example: (to) => {
    //   if (to.query.q && to.name === 'search') {
    //     return { keyword: to.query.q, category: to.params.category }
    //   } else {
    //    return null
    //   }
    // }
    trackSiteSearch: (to) => {
        if (to.query.q && to.name === 'search') {
            return {keyword: to.query.q, category: to.params.category}
        } else {
            return null
        }
    }
})
app.mount('#app')
