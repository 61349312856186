
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {ScreensEnum} from "@/Screens.enum";

export default defineComponent({
  name: 'AlternativeSupplyScreen',
  components: {
    ScreenWrapper,
    // ReadMore,
    // Card
  },
  props: {
    msg: String,
    goTo: Function
  },

  data() {
    return {
      screen: ScreensEnum.ALTERNATIVE_SUPPLY,
      option: null,
    }
  },

  mounted() {
    setTimeout(() => {
      this.$root.setupAndPlayIfOn(this.FILES.audio_alternative_supply)
    }, 1000)
  },

  methods: {
    next() {
      this.goTo(ScreensEnum.GET_MAGNETS)
    },
    toggleOption(selected) {
      if (this.option === selected) {
        this.option = null;
      } else {
        this.option = selected
      }
    }
  }
});
