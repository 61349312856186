export enum ScreensEnum {
    WELCOME = 'welcome',
    LETS_START = 'lets-start',
    VIDEO = 'video',
    DEVICES = 'devices',
    MAP = 'map',
    MAGNETS_STRUCTURE = 'magnets-structure',
    MAGNETS_PROCESS = 'magnets-process',
    CLOSE_LOOP = 'close-loop',
    CHALLENGES = 'challenges',
    FEEDBACK = 'feedback',
    ALTERNATIVE_SUPPLY = 'alternative-supply',
    GET_MAGNETS = 'get-magnets',
    ACTION_TIME = 'action-time',
}
