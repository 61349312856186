
import {defineComponent} from 'vue';
import IntroScreen from "@/components/IntroScreen.vue";
import Step2Screen from "@/components/Step2Screen.vue";
import SelectDevicesScreen from "@/components/SelectDevicesScreen.vue";
import MapScreen from "@/components/MapScreen.vue";
import TransitionWrapper from "@/components/elements/TransitionWrapper.vue";
import MagnetsStructureScreen from "@/components/MagnetsStructureScreen.vue";
import MagnetsProcessScreen from "@/components/MagnetsProcessScreen.vue";
import Question1Screen from "@/components/Question1Screen.vue";
import ChallengesScreen from "@/components/ChallengesScreen.vue";
import AlmostEndScreen from "@/components/AlmostEndScreen.vue";
import NowWhatScreen from "@/components/NowWhatScreen.vue";
import {ScreensEnum} from '@/Screens.enum';
import PeriodicScreen from "@/components/PeriodicScreen.vue";
import AlternativeSupplyScreen from "@/components/AlternativeSupplyScreen.vue";
import GetMagnetsScreen from "@/components/GetMagnetsScreen.vue";
import {audioPlayerMixin} from "@/mixins/audio";

export default defineComponent({
  name: 'App',
  mixins: [audioPlayerMixin],
  components: {
    TransitionWrapper,
    IntroScreen,
    Step2Screen,
    SelectDevicesScreen,
    PeriodicScreen,
    MapScreen,
    MagnetsStructureScreen,
    MagnetsProcessScreen,
    Question1Screen,
    ChallengesScreen,
    AlmostEndScreen,
    NowWhatScreen,
    GetMagnetsScreen,
  },
  data() {
    const stepInHash = location.hash.substring(1) || ScreensEnum.WELCOME;

    return {
      ScreensEnum,
      totalSteps: 5,
      isGoingBack: false,
      currentScreen: Object.values(ScreensEnum).includes(stepInHash as ScreensEnum) ? stepInHash : ScreensEnum.WELCOME,
      screens: [
        {key: ScreensEnum.WELCOME, screen: IntroScreen},
        {key: ScreensEnum.LETS_START, screen: Step2Screen},
        {key: ScreensEnum.DEVICES, screen: SelectDevicesScreen},
        {key: ScreensEnum.VIDEO, screen: PeriodicScreen},
        {key: ScreensEnum.MAP, screen: MapScreen},
        {key: ScreensEnum.ALTERNATIVE_SUPPLY, screen: AlternativeSupplyScreen},
        {key: ScreensEnum.GET_MAGNETS, screen: GetMagnetsScreen},
        {key: ScreensEnum.MAGNETS_STRUCTURE, screen: MagnetsStructureScreen},
        {key: ScreensEnum.MAGNETS_PROCESS, screen: MagnetsProcessScreen},
        {key: ScreensEnum.CLOSE_LOOP, screen: Question1Screen},
        {key: ScreensEnum.CHALLENGES, screen: ChallengesScreen},
        {key: ScreensEnum.FEEDBACK, screen: AlmostEndScreen},
        {key: ScreensEnum.ACTION_TIME, screen: NowWhatScreen},
      ]
    }
  },

  watch: {
    '$i18n.locale': function (locale) {
      console.log('Locale changed', locale);
      if (this.audioOn) {
        const translatedFile = this.findFileInLocale(this.url, locale)
        if (translatedFile) {
          this.setupAndPlayIfOn(translatedFile)
        } else {
          this.stopAudio()
        }
      }
    }
  },

  mounted() {
    window["_paq"]?.push(['trackPageView'])

    if (location.hash.substring(1) !== this.currentScreen) {
      location.hash = '#' + this.currentScreen;
    }

    window.addEventListener('hashchange', () => {
      const next = location.hash.substring(1) || ScreensEnum.WELCOME;
      if (this.currentScreen === next) {
        return;
      }
      this.goTo(next);
    });

    const queryParams = new URLSearchParams(window.location.search)
    this.$i18n.locale = queryParams.get('locale') || process.env.VUE_APP_I18N_LOCALE || 'en';

    console.log('Starting with', this.$i18n.locale);
  },
  methods: {
    goTo(screen) {
      const currentIndex = this.screens.findIndex(scr => scr.key === this.currentScreen);
      const nextIndex = this.screens.findIndex(scr => scr.key === screen);
      console.log(currentIndex, nextIndex);
      this.isGoingBack = currentIndex !== undefined && nextIndex !== undefined && nextIndex < currentIndex;

      this.$nextTick(() => {
        this.currentScreen = screen
        location.hash = '#' + this.currentScreen

        window["_paq"]?.push(['trackEvent', 'ScreenChange', this.currentScreen])
      })
    },
  }
});
