import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["rounded-md p-4 sm:p-8 bg-white text-black relative", _ctx.wrapperClass])
  }, [
    (_ctx.close)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeCard())),
          class: "cursor-pointer right text-pxorange text-4xl absolute top-0 right-2"
        }, "×"))
      : _createCommentVNode("", true),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h4", {
          key: 1,
          class: _normalizeClass(["text-xl text-bronze-DEFAULT-600 font-space text-center font-bold mb-4 sm:mb-5", _ctx.titleClass])
        }, _toDisplayString(_ctx.title), 3))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}