import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", {
      class: "text-pxorange cursor-pointer mb-2 hover:underline",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, _toDisplayString(_ctx.show ? _ctx.$t('read less') : _ctx.$t('read more')) + " " + _toDisplayString(_ctx.show ? '×' : '»'), 1),
    _createVNode(_Transition, { name: "show" }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "default")
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}