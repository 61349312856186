
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {ScreensEnum} from "@/Screens.enum";

export default defineComponent({
  name: 'MagnetsProcessScreen',
  components: {
    ScreenWrapper
  },
  props: {
    msg: String,
    goTo: Function
  },

  data() {
    return {
      screen: ScreensEnum.MAGNETS_PROCESS,
    }
  },

  mounted() {
    setTimeout(() => {
      this.$root.setupAndPlayIfOn(this.FILES.audio_magnet_process)
    }, 1000)
  },


  methods: {
    next() {
      this.goTo(ScreensEnum.CLOSE_LOOP)
    }
  }
});
