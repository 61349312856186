export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])},
  "screen": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!"])},
    "lets-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's start..."])},
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try to guess"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are REEs?"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who owns REEs?"])},
    "alternative-supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our alternatives"])},
    "get-magnets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The magnets Lab"])},
    "magnets-structure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making alloys"])},
    "magnets-process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final step"])},
    "close-loop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the loop!"])},
    "challenges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Three challenges"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your feedback!"])},
    "action-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action time"])},
    "guess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can you guess which of these products contain Rare Earths alloys / magnets?"])}
  },
  "almost-end": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are almost at the end of the Citizen Lab!"])},
    "insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient"])},
    "barely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barely"])},
    "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
    "satisfying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satisfying"])},
    "great": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't think it will affect my decisions"])},
    "prioritize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I will likely prioritize sustainability over other factors such as price"])},
    "will-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I will pay more attention to sustainability but other factors are more important to me, such as price"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are almost at the end of the Citizen Lab! We just have a couple of questions for you before we say goodbye"])},
    "question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How would you rate your understanding of Rare Earths and their challenges now that you've reached the end of this Citizen Lab?"])},
    "question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now, you know that rare earths are present in a lot of items you use daily and that there are varying sustainability standards in their production. If you would have access to information on the sustainability of those products (e.g. through a certificate), would this affect your future purchasing decisions?"])}
  },
  "eu-footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These two projects have received funding from the European Union's Horizon 2020 Research and Innovation <br> Programme under Grant Agreement No 776559 (SecREEts) and No 821114 (SUSMAGPRO)"])},
  "buttons": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YES"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])}
  },
  "read less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["read less"])},
  "read more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["read more"])},
  "map": {
    "button-instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the map to select region"])},
    "card-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China provides 98% of the EU's supply of REEs. This means all products we identified earlier are dependent on China supplying the material."])},
    "map-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The regional division on this map is based on"])},
    "map-notice-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this article"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even though REEs can be found in many places in the world, one region currently dominates the mining and manufacturing of Rare Earths, as well as the production of Rare Earths magnets. Can you guess which one?"])}
  },
  "That's correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That's correct"])},
  "That's incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That's incorrect"])},
  "intro": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DiscoveREEs"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a Virtual Citizen Lab by <br> SecREEts & SUSMAGPRO"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to know a secREEt?"])},
    "par1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to this online Citizen Lab, an interactive platform for exploring issues and solutions, and sharing views. This platform is run by SecREEts and SUSMAGPRO, two EU funded research projects that engage European citizens to inform about and get feedback on scientific innovations that have an impact on our daily lives. You don't need to be an expert to participate, the Citizen Lab is open to everyone."])},
    "par2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this Citizen Lab we explore Rare Earth Elements (REEs) used in particular in the production of alloys and permanent magnets, why they have become so important for our society and economy and which challenges are connected to them. We look at how to recover these precious REEs and support their wider recycling and reuse."])},
    "par3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are ready to start the citizen lab, then click on the button."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's talk Magnets"])}
  },
  "step2": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you familiar with Rare Earth Elements?"])},
    "okcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some of them are needed to produce <b>alloys and permanent magnets</b> that make many of <b>the products we use on a daily basis</b> more efficient"])},
    "nokcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actually, not many people are aware of them. Some of them are needed to produce <b>alloys and permanent magnets</b> that make many of <b>the products we use on a daily basis</b> more efficient."])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press the arrow <br> to continue"])}
  },
  "Great!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great!"])},
  "Check answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check answers"])},
  "Click or tap on the images above": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click or tap on the images above"])},
  "devices": {
    "card": {
      "par1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of the products shown contain Rare Earths alloys / magnets! Our modern societies and economies depend heavily on them. Let's take a closer look at what these Rare Earth Elements are."])},
      "par2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you know why we call them \"rare?\""])}
    }
  },
  "alternativeSupply": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating Alternative Supply"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Several countries are setting up alternative supplies of Rare Earths, but in Europe we want to make sure that Rare Earths supply, in particular for alloy and permanent magnet production:"])},
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is better for the environment."])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meets health, safety and human rights standards."])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivers sustainable production and use."])},
    "bullet4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supports the Sustainable Development Goals as defined by the <br> United Nations"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To do this we need to change the way Rare Earths are produced, used and disposed of, and this is where the fun starts. Thanks to SecREEts and SUSMAGPRO pioneering industrial process, things can be done differently, and <b>you can create a 100% sustainable magnet</b>. Take a look below and have fun experimenting the various ways to build your magnet!"])}
  },
  "getMagnets": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where do you want to get your magnet from?"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That's a smart choice!"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you know?"])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wise decision!"])},
    "fertiliser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From fertiliser!"])},
    "recycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I will recycle it!"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rare Earths are naturally present in some phosphate containing rocks better known as apatite, which is used in the production of fertilisers. Obviously, plants do not need the rare earths in fertilisers to grow! So SecREEts is developing a process to extract Rare Earths from the phosphate rock used in the fertiliser production process. This technology helps avoid any additional mining by maximising the value of already existing production processes."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There can be up to 17 different Rare Earth Elements depending on the definition and they each have different applications! SecREEts is developing an environment-friendly process to separate Rare Earths from one another and turn them into high-purity oxides."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These multicolour powders can then be selected depending on what element is needed and in which quantity."])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you know that many discarded products contain significant quantities of Rare Earths? Instead of relying on mining and processing of virgin materials, SUSMAGPRO decided to go for recycling. They are developing automatic sorting lines that efficiently identify and extract those parts entailing magnets full of REEs (“RE magnets”) from computer hard disk drives, loudspeakers, game controllers and further items that are not in use anymore."])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Furthermore, specific information on composition and location of Rare Earths magnets found in waste products is collected in a database and will be made available to sorting and recycling companies. This will make the dismantling of parts and extraction of magnets easier, and as a result, recycling more attractive. The gathered data also feeds into product design processes, so these items are created in a way that makes it easier to extract and recycle the magnets from the products once they have reached the end of their life."])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of course, having all this information is of no value if old products are hidden away in drawers and dark corners of basements, so get your used electronics out of there and take them to the next official collection point!"])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In SUSMAGPRO, the extracted parts from end-of-life products containing Rare Earths magnets are broken down into powders with the help of hydrogen in a closed reactor, through the so-called Hydrogen Processing of Magnetic Scrap (HPMS). With this patented technology, you can dissolve not only the magnets, but also the material surrounding it, thus making recycling easier with less time to completely extract magnets."])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mixed powders are then separated and purified to result in high-quality Rare Earths powders ready to directly produce alloys or magnets, saving time, resources, energy, and money compared to the conventional recycling processes for end-of-life components."])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hydrogen Processing of Magnetic Scrap (Pforzheim University for SUSMAGPRO)"])},
    "checkVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the video &#187;"])}
  },
  "magnetStructure": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now we have our Rare Earths ready to use, great! But Rare Earths alone cannot do much: in most applications, they are alloyed to other more common metals, such as iron. In SecREEts and SUSMAGPRO, metals and alloys can be produced with the right amount of the right Rare Earths needed for their end use."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For strong permanent magnets, the most common alloy is composed of neodymium (Nd), with iron (Fe) and boron (B): we call these NdFeB magnets."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To get this NdFeB alloy, we start by melting the neodymium oxide using an innovative electrolysis process. The resulting neodymium is then alloyed with iron and boron in a strip cast furnace at a very high temperature before being turned into flakes. In the final stage, the alloy is processed into powder in the Hydrogen Decrepitation furnace. And then, we are very close to having our final permanent magnets!"])}
  },
  "magnetsProcess": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final step: <br> turning NdFeB powder into magnets!"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depending on the requirements and application, there are various ways to do that. Indeed, a magnet in a wind turbine will not have the same shape or size as a magnet in your smartphone speaker! <br> While the most common production method called <b>“sintering”</b> is used by both SUSMAGPRO and SecREEts, SUSMAGPRO also uses other methods."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whichever way of production is chosen, it generally includes a preliminary treatment of the powder (such as <b>milling</b> to make the powder particles thinner, for example), so that it can be easily shaped and starts looking like a magnet. Here, the powder can be aligned in the same direction using a magnetizing field, increasing the strength of the magnet. Processing follows at high, stable temperature making sure to remove remaining oxygen from the part, as this could affect the performance of the magnet. This is also the moment where we protect the magnet from corrosion, using methods such as <b>coating</b>. In a final step, the part is magnetised."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And there you go: a permanent magnet 100% made in Europe, fresh from the press and ready to be used in a lot of end products!"])}
  },
  "question1": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now, your fridge or your smartphone breaks down and can't be repaired: too bad! But this is not the end for the permanent magnets in there: if you dispose of your items responsibly, they can re-enter the SUSMAGPRO cycle."])},
    "btnBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s make another magnet!"])},
    "btnNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enough magnets for today"])}
  },
  "challenges": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What important challenges are we addressing here?"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Rare Earths alloys and magnet supply is connected to a variety of challenges. <br> Click below to find out how SUSMAGPRO and SecREEts address these:"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mining and processing are linked to environmental damage and health & safety issues"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rare Earths recycling, in particular for the production of alloys and permanent magnets, is too low"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High demand and dependency for Rare Earths alloys and magnet supply"])},
    "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
    "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SecREEts & SUSMAGPRO solutions"])},
    "card1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mining and processing are linked to environmental damage and health & safety issues"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current mining and processing methods generate toxic and radioactive waste as a by-product. If regulations are not in place or not adhered to, acids, heavy metals and radioactive elements may pollute groundwater. Further problems are damaged vegetation and soil erosion. Furthermore, there is a lack of transparency when it comes to value chains and little or no way of knowing what social, safety and environmental standards are applied."])},
      "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SecREEts recovers Rare Earths from European apatite sources used in fertilizer production."])},
      "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUSMAGPRO recovers Rare Earths from end-of-life magnets in various European waste products."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both projects make use of secondary sources of Rare Earths:"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These recovered Rare Earths can then be used to produce alloys, permanent magnets, or other products. This way, SecREEts and SUSMAGPRO are supporting more circularity and sustainability in the European value chains. Both projects are also assessing their social and environmental impacts and putting health & safety at the core of their value chains."])}
    },
    "card2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rare Earths recycling, in particular for the production of alloys and permanent magnets, is too low"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently, the recycling rate of Rare Earths is very low. In order to establish a true circular economy and ensure valuable resources are not wasted, increasing this rate is essential."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both projects develop solutions for re-using and transforming some waste and side-streams containing Rare Earths. Even if some of these solutions are innovations, they are ready to be used by the industry. Their broader use will allow to make them even more efficient."])}
    },
    "card3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High demand and dependency for Rare Earths alloys and magnet supply"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With increasing digitalisation and the green energy transition, European demand for Rare Earths is high and expected to rise even further in the future. Less than 10% of the current demand for NdFeB is also met by EU production and the market is dominated by one country. This results in high price volatility and supply risk."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both projects use alternative supply sources and create production facilities in Europe, contributing to the establishment of a more resilient and secure supply chain of Rare Earths, as well as job creations for Europe."])}
    }
  },
  "nowWhat": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now, what can you do?"])},
    "section1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get informed!"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here are some useful links for more information about Rare Earth Elements: <br>"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where does your smartphone come from? Discover it with"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also visit the"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["websites for further information on the projects!"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are also on social media:<br>"])},
      "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through their approaches, SUSMAGPRO and SecREEts support several of the United Nations Sustainable Development Goals. Learn more about the"])},
      "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and how you can support them too!"])},
      "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this interactive platform."])}
    },
    "section2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support recycling!"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispose of waste properly to contribute to urban mining (the process of recovering raw materials from citizens through recycling of waste)"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find out how many valuable materials are in your home with this"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-waste-calculator"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask questions to sales representatives or sellers:<br>"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where do products come from?<br>"])},
      "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How are they produced?<br>"])},
      "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What happens at their end of life?<br>"])}
    },
    "section3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass on the message!"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raise awareness among your peers: here is some useful, easy-to-read material you can share."])}
    },
    "section4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask us your questions!"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you would like to contact us with specific questions or points, then please send us an email at"])}
    }
  }
}