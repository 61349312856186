
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import Card from "@/components/elements/Card.vue";
import {ScreensEnum} from "@/Screens.enum";

export default defineComponent({
  name: 'Step2Screen',
  components: {
    ScreenWrapper, Card
  },
  props: {
    msg: String,
    goTo: Function
  },
  data() {
    return {
      screen: ScreensEnum.LETS_START,
      answer: null,
    }
  },

  mounted() {
    setTimeout(() => {
      this.$root.setupAndPlayIfOn(this.FILES.audio_areyoufamiliar)
    }, 1000)
  },

  methods: {

    next() {
      this.goTo(ScreensEnum.DEVICES)
    },

    setAnswer(ans) {
      this.answer = ans

      if (ans) {
        this.$root.setupAndPlayIfOn(this.FILES.audio_areyoufamiliar_yes)
      } else {
        this.$root.setupAndPlayIfOn(this.FILES.audio_areyoufamiliar_no)
      }
    },
  }
});
