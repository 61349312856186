export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "screen": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen!"])},
    "lets-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lassen Sie uns beginnen..."])},
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie, zu raten"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was sind SE?"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wem gehören SE?"])},
    "alternative-supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Alternativen"])},
    "get-magnets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Magnetlabor"])},
    "magnets-structure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legierungsherstellung"])},
    "magnets-process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Schritt"])},
    "close-loop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den Kreis schliessen!"])},
    "challenges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drei Herausforderungen"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Feedback!"])},
    "action-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit für Action"])},
    "guess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Können Sie erraten, welche dieser Produkte Legierungen oder Magnete aus Seltenen Erden enthalten?"])}
  },
  "almost-end": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind fast am Ende unseres Bürgerlabors angelangt!"])},
    "insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unzureichend"])},
    "barely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaum ausreichend"])},
    "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gut"])},
    "satisfying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zufriedenstellend"])},
    "great": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Großartig"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich glaube nicht, dass es meine Entscheidungen beeinflussen wird"])},
    "prioritize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich werde wahrscheinlich der Nachhaltigkeit Vorrang vor anderen Faktoren wie dem Preis geben"])},
    "will-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich werde mehr auf Nachhaltigkeit achten, aber andere Faktoren sind mir wichtiger, wie z. B. der Preis"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind fast am Ende unseres Bürgerlabors angelangt! Wir haben nur noch ein paar Fragen bevor wir uns verabschieden."])},
    "question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie würden Sie Ihr Wissen über Seltene Erden und damit verbundene Herausforderungen bewerten, nun da Sie das Ende dieses Bürgerlabors erreicht haben?"])},
    "question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie wissen, dass Seltene Erden in vielen Produkten enthalten sind, die Sie täglich benutzen, und dass es bei ihrer Herstellung unterschiedliche Nachhaltigkeitsstandards gibt. Wenn Sie Zugang zu Informationen über die Nachhaltigkeit dieser Produkte hätten (z. B. durch ein Zertifikat), würde dies Ihre zukünftigen Kaufentscheidungen beeinflussen?"])}
  },
  "eu-footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die beiden Projekte werden durch das Forschungs und Innovationsprogramm Horizont 2020 der Europäischen Union unter den Finanzhilfevereinbarungen Nr. 776559 (SecREEts) und Nr. 821114 (SUSMAGPRO) gefördert."])},
  "buttons": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einreichen"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEIN"])}
  },
  "read less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger lesen"])},
  "read more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr lesen"])},
  "map": {
    "button-instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf die Karte, um eine Region auszuwählen"])},
    "card-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China stellt 98% der Lieferungen von Seltenen Erden an die EU. Das bedeutet, dass alle Produkte, die wir zuvor identifiziert haben, davon abhängig sind, dass China eines der Materialien liefert."])},
    "map-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die regionale Einteilung auf dieser Karte basiert auf"])},
    "map-notice-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diesem Artikel"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obwohl Seltene Erden an vielen Orten der Welt zu finden sind, dominiert derzeit ein Land den Abbau von Seltenen Erden und die Produktion von Seltenerdmagneten. Können Sie erraten, welches Land das ist?"])}
  },
  "That's correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das stimmt"])},
  "That's incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das ist falsch"])},
  "intro": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DiscoveREEs"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein virtuelles Bürgerlabor von <br> SecREEts und SUSMAGPRO"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweitern Sie Ihr WisSEn!"])},
    "par1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen in diesem Online-Bürgerlabor, einer interaktiven Plattform zur Erforschung von Problemen und ihren Lösungen und zum Meinungsaustausch. Diese Plattform wird von SecREEts und SUSMAGPRO betrieben, zwei von der EU finanzierten Forschungsprojekten. Ziel ist es, europäische Bürgerinnen und Bürger einzubeziehen, über wissenschaftliche Innovationen zu informieren, die sich auf unser tägliches Leben auswirken, und Feedback einzuholen. Sie müssen kein Experte sein, um mitzumachen, das Bürgerlabor ist für jeden offen."])},
    "par2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Bürgerlabor erforschen wir Seltene Erden (SE), die insbesondere für die Herstellung von Legierungen und Permanentmagneten verwendet werden, sowie die Frage, warum sie für unsere Gesellschaft und Wirtschaft so wichtig sind und welche Herausforderungen sich dadurch ergeben. Wir werfen einen Blick darauf, wie man diese wertvollen Rohmaterialien zurückgewinnen und Recycling und Wiederverwendung fördern kann."])},
    "par3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie bereit sind, das Bürgerlabor zu starten, dann klicken Sie auf den Link."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lassen Sie uns über Magnete sprechen"])}
  },
  "step2": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennen Sie sich mit Seltenen Erden aus?"])},
    "okcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Teil der Seltenen Erden wird für die <b>Herstellung von Legierungen und Permanentmagneten benötigt</b>, die viele der Produkte, <b>die wir täglich benutzen</b>, effizienter machen."])},
    "nokcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tatsächlich sind sie nicht vielen Menschen bekannt. Ein Teil der Seltenen Erden wird für die <b>Herstellung von Legierungen und Permanentmagneten benötigt</b>, die viele der Produkte, <b>die wir täglich benutzen</b>, effizienter machen."])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf den Pfeil, <br> um fortzufahren"])}
  },
  "Great!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Großartig!"])},
  "Check answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten prüfen"])},
  "Click or tap on the images above": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken oder tippen Sie auf die Bilder oben"])},
  "devices": {
    "card": {
      "par1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Produkte, die Sie ausgewählt haben, enthalten Legierungen oder Magnete aus Seltenen Erden! Unsere moderne Gesellschaft und Wirtschaft hängen stark von ihnen ab. Schauen wir uns einmal genauer an, was Seltene Erden sind."])},
      "par2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wissen Sie, warum sie als selten bezeichnet werden?"])}
    }
  },
  "alternativeSupply": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine alternative Versorgung aufbauen"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrere Länder sind dabei, eine alternative Versorgung mit Seltenen Erden aufzubauen. In Europa wollen wir sicherstellen, dass der Abbau und die Weiterverarbeitung von Seltenen Erden, insbesondere für die Herstellung von Legierungen und Permanentmagneten:"])},
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["besser für die Umwelt sind,"])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesundheits-, Sicherheits- und Menschenrechtsstandards erfüllen"])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eine nachhaltige Produktion und Nutzung ermöglichen"])},
    "bullet4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und schließlich, dass sie auch die von den Vereinten Nationen definierten Ziele für nachhaltige Entwicklung unterstützen.\n"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um dies zu erreichen, müssen wir die Art und Weise ändern, in der Seltene Erden gewonnen, verwendet und entsorgt werden, und hier beginnt der Spaß. Dank SecREEts und dem innovativen industriellen Verfahren von SUSMAGPRO ist es möglich, einen <b>100% nachhaltigen Magneten herzustellen</b>. Werfen Sie einen Blick darauf und haben Sie Spaß beim Experimentieren mit den verschiedenen Möglichkeiten, Ihren Magneten herzustellen!"])}
  },
  "getMagnets": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woher möchten Sie Ihren Magneten gewinnen?"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das ist eine kluge Entscheidung!"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wussten Sie schon?"])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine kluge Entscheidung!"])},
    "fertiliser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus der Düngemittelproduktion!"])},
    "recycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Recycling!"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seltene Erden sind von Natur aus in einigen phosphathaltigen Gesteinen, besser bekannt als Apatit, enthalten, die zur Herstellung von Düngemitteln verwendet werden. Offensichtlich brauchen Pflanzen die Seltenen Erden in Düngemitteln nicht, um zu wachsen! Daher entwickelt SecREEts ein Verfahren zur Gewinnung von Seltenen Erden aus dem Phosphatgestein, das bei der Düngemittelherstellung verwendet wird. Diese Technologie trägt dazu bei, zusätzlichen Bergbau zu vermeiden, indem der Wert bereits bestehender Produktionsprozesse maximiert wird."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je nach Definition gibt es bis zu 17 verschiedene Seltene Erden, die alle unterschiedliche Anwendungen haben! SecREEts entwickelt ein umweltfreundliches Verfahren, um Seltene Erden voneinander zu trennen und in hochreine Oxide zu verwandeln."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese mehrfarbigen Pulver können dann je nachdem, welches Element in welcher Menge benötigt wird, ausgewählt werden."])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wussten Sie, dass viele ausrangierte Produkte erhebliche Mengen an Seltenen Erden enthalten? Anstatt sich auf den Abbau und die Verarbeitung von Neuware zu verlassen, wird in SUSMAGPRO das Recycling erforscht. Im Rahmen des Projekts wird eine automatische Sortieranlage entwickelt, mit der die Teile, die Magnete mit Seltenen Erden enthalten (\"SE-Magnete\"), aus Computerfestplatten, Lautsprechern, Game Controllern und anderen Gegenständen, die nicht mehr in Gebrauch sind, effizient identifiziert und extrahiert werden können."])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darüber hinaus werden spezifische Informationen über die Zusammensetzung und die Position von Seltenerdmagneten, die in Abfallprodukten gefunden werden, in einer Datenbank gesammelt und Sortier- und Recyclingunternehmen zur Verfügung gestellt. Dies erleichtert die Demontage von Teilen und die Extraktion von Magneten und macht so das Recycling attraktiver. Die gesammelten Daten fließen auch in die Produktentwicklung ein, so dass die Produkte so gestaltet werden, dass die Magnete am Ende ihrer Lebensdauer leichter aus den Produkten entfernt und recycelt werden können."])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natürlich nützen all diese Informationen nichts, wenn ausgediente Produkte in den hintersten Schränken und dunklen Kellerecken versteckt werden. Bitte suchen Sie also Ihre gebrauchten Elektrogeräte heraus und bringen Sie sie zur nächsten offiziellen Sammelstelle!"])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In SUSMAGPRO werden die Komponenten, die Seltenerdmagnete enthalten und am Ende ihrer Lebensdauer aus den Produkten extrahiert wurden, mit Hilfe von Wasserstoff in einem geschlossenen Reaktor durch das sogenannte Hydrogen Processing of Magnetic Scrap (HPMS) in Pulver zerlegt. Mit dieser patentierten Technologie kann man oft nicht nur den Magneten, sondern auch das ihn umgebende Material in ein Pulver überführen, was das Recycling erleichtert und den Zeitaufwand für die vollständige Extraktion der Magnete verringert."])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die gemischten Pulver werden dann getrennt und gereinigt, sodass hochwertiges Seltene Erden-Pulver entsteht, das direkt zur Herstellung von Legierungen oder Magneten verwendet werden kann. Im Vergleich zu herkömmlichen Recyclingverfahren für Altkomponenten werden Zeit, Ressourcen, Energie und Geld gespart."])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hydrogen Processing of Magnetic Scrap (Pforzheim University for SUSMAGPRO)"])},
    "checkVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schauen Sie sich das Video an &#187;"])}
  },
  "magnetStructure": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt sind unsere Seltenen Erden einsatzbereit, großartig! Aber Seltene Erden allein können nicht viel ausrichten: In den meisten Anwendungen werden sie mit anderen, gebräuchlicheren Metallen, wie z. B. Eisen, legiert. In SecREEts und SUSMAGPRO können Legierungen mit der richtigen Menge der richtigen Seltenen Erden für die spezifische Endanwendung hergestellt werden."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die für starke Permanentmagnete am häufigsten verwendete Legierung besteht aus Neodym (Nd), Eisen (Fe) und Bor (B)."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um diese Neodym-Eisen-Bor-Legierung zu erhalten, wird zunächst Neodymoxid in einem innovativen Elektrolyseverfahren geschmolzen. Das so entstandene Neodym wird dann in einem Bandgießofen bei sehr hoher Temperatur mit Eisen und Bor legiert, bevor es zu Flocken verarbeitet wird. In der letzten Stufe wird die Legierung im Wasserstoff-Versprödungs-Ofen zu Pulver verarbeitet. Und dann sind unsere Permanentmagnete schon fast fertig!"])}
  },
  "magnetsProcess": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Schritt: <br> Umwandlung von Neodym-Eisen-Bor-Pulver in Magnete!"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je nach Anforderungen und Anwendung gibt es verschiedene Möglichkeiten. Ein Magnet in einer Windkraftanlage hat natürlich nicht die gleiche Form oder Größe wie ein Magnet in Ihrem Smartphone-Lautsprecher! <br> Das gängigste Herstellungsverfahren, das <b>\"Sintern\"</b>, wird sowohl in SUSMAGPRO als auch in SecREEts verwendet. In SUSMAGPRO werden zusätzlich noch weitere Verfahren angewandt."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welches Produktionsverfahren auch immer gewählt wird, es beinhaltet normalerweise eine Vorbehandlung des Legierungspulvers (z. B. <b>Mahlen</b>, um die Pulverpartikel kleiner zu machen), so dass es leicht geformt werden kann und beginnt, wie ein Magnet auszusehen. Hier kann das Pulver mit Hilfe eines Magnetfeldes in die gleiche Richtung ausgerichtet werden, was die Stärke des Magneten erhöht. Es folgt eine Verarbeitung bei hoher und stabiler Temperatur, wobei darauf geachtet wird, dass der restliche Sauerstoff aus dem Magneten entfernt wird, da dieser die Leistung des Magneten beeinträchtigen könnte. Dies ist auch der Moment, in dem wir den Magneten mit Beschichtung vor Korrosion schützen. In einem letzten Schritt wird das Teil magnetisiert."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Und fertig, frisch aus der Presse, haben wir einen Permanentmagnet, der zu 100 % in Europa hergestellt wurde und in einer Vielzahl von Endprodukten eingesetzt werden kann!"])}
  },
  "question1": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt geht Ihr Kühlschrank oder Ihr Smartphone kaputt und kann nicht mehr repariert werden: schade! Aber das ist nicht das Ende für die darin verwendeten Permanentmagnete: Wenn Sie Ihre Produkte verantwortungsvoll entsorgen, können sie wieder in den SUSMAGPRO-Kreislauf eintreten."])},
    "btnBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch einen Magneten herstellen!"])},
    "btnNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genug Magnete für heute"])}
  },
  "challenges": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche wichtigen Herausforderungen gehen wir hier an?"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die derzeitige Versorgung mit Seltenen Erden und Magneten ist mit einer Vielzahl von Herausforderungen verbunden. <br> Klicken Sie auf den Text, um herauszufinden, wie SUSMAGPRO und SecREEts diese angehen:"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbau und Verarbeitung sind mit Umweltschäden sowie Gesundheits- und Sicherheitsproblemen verbunden"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Recyclingquote für Seltene Erden, insbesondere für die Herstellung von Legierungen und Permanentmagneten, ist zu niedrig"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hohe Nachfrage und Abhängigkeit bei Legierungen und Magneten aus Seltenen Erden"])},
    "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr lesen"])},
    "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SecREEts & SUSMAGPRO-Lösungen"])},
    "card1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbau und Verarbeitung sind mit Umweltschäden sowie Gesundheits- und Sicherheitsproblemen verbunden"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei den derzeitigen Abbau- und Verarbeitungsmethoden fallen giftige und radioaktive Abfälle als Nebenprodukt an. Wenn es keine Vorschriften gibt oder diese nicht eingehalten werden, können Säuren, Schwermetalle und radioaktive Elemente das Grundwasser verschmutzen. Weitere Probleme sind Schädigung der Vegetation und Bodenerosion. Außerdem mangelt es an Transparenz in den Wertschöpfungsketten, und es ist kaum oder gar nicht bekannt, welche Sozial-, Sicherheits- und Umweltstandards angewandt werden."])},
      "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In SecREEts werden Seltene Erden aus europäischen Apatitquellen gewonnen, die in der Düngemittelproduktion verwendet werden."])},
      "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In SUSMAGPRO werden Seltene Erden aus ausgedienten Magneten in verschiedenen europäischen Abfallprodukten zurückgewonnen."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In beiden Projekten werden sekundäre Quellen genutzt:"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Seltenen Erden können dann zur Herstellung von Legierungen, Permanentmagneten oder anderen Produkten verwendet werden. Auf diese Weise unterstützen SecREEts und SUSMAGPRO mehr Kreislaufwirtschaft und Nachhaltigkeit in den europäischen Wertschöpfungsketten. In beiden Projekten werden zudem soziale und ökologische Auswirkungen der Projektaktivitäten bewertet und Gesundheit und Sicherheit in den Mittelpunkt der Wertschöpfungsketten gestellt."])}
    },
    "card2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Recyclingquote für Seltene Erden, insbesondere für die Herstellung von Legierungen und Permanentmagneten, ist zu niedrig"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derzeit ist die Recyclingquote für Seltene Erden sehr niedrig. Um eine echte Kreislaufwirtschaft zu etablieren und sicherzustellen, dass wertvolle Ressourcen nicht verschwendet werden, ist eine Erhöhung dieser Quote unerlässlich."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In beiden Projekten werden Lösungen für die Wiederverwendung und Umwandlung von Abfällen und Nebenströmen, die Seltene Erden enthalten, entwickelt. Auch wenn es sich bei einigen dieser Lösungen um Innovationen handelt, können sie von der Industrie sofort eingesetzt werden. Durch eine breitere Anwendung können sie noch effizienter werden."])}
    },
    "card3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hohe Nachfrage und Abhängigkeit bei Legierungen und Magneten aus Seltenen Erden"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit zunehmenden Digitalisierung und der grünen Energiewende ist die Nachfrage nach Seltenen Erden in Europa hoch und wird in Zukunft voraussichtlich noch weiter steigen. Weniger als 10 % der derzeitigen Nachfrage nach Neodym-Eisen-Bor wird durch EU-Produktion gedeckt, und der Markt wird von einem einzigen Land beherrscht. Dies führt zu einer hohen Preisvolatilität und einem hohen Versorgungsrisiko."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beide Projekte nutzen alternative Versorgungsquellen und schaffen Produktionsanlagen in Europa, was zum Aufbau einer widerstandsfähigeren und sichereren Versorgungskette für Seltene Erden sowie zur Schaffung von Arbeitsplätzen in Europa beiträgt."])}
    }
  },
  "nowWhat": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was können Sie jetzt tun?"])},
    "section1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informieren Sie sich!"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier sind einige nützliche Links zu weiteren Informationen über Seltene Erden: <br>"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woher kommt Ihr Smartphone? Entdecken Sie es mit"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besuchen Sie auch die Websites von"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", um weitere Informationen über die Projekte zu erhalten!"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind auch in den sozialen Medien vertreten:<br>"])},
      "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUSMAGPRO und SecREEts unterstützen mehrere der Ziele für Nachhaltige Entwicklung der Vereinten Nationen. Erfahren Sie mehr über die"])},
      "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und wie auch Sie sie unterstützen können!"])},
      "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dieser interaktiven Plattform."])}
    },
    "section2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützen Sie Recycling!"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entsorgen Sie Abfälle ordnungsgemäß und tragen Sie so zum Urban Mining (dem Prozess der Rückgewinnung von Rohstoffen von der Bevölkerung durch Recycling von Abfällen) bei"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finden Sie mit diesem "])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" heraus, wie viele wertvolle Materialien sich in Ihrem Haushalt befinden."])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen Sie Fragen vor dem Kauf:<br>"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woher kommen die Produkte?<br>"])},
      "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie werden sie hergestellt?<br>"])},
      "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was passiert am Ende ihrer Lebensdauer?<br>"])}
    },
    "section3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Botschaft weiter!"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensibilisieren Sie Ihre Mitmenschen: Hier finden Sie nützliches, leicht verständliches Material, das Sie weitergeben können."])}
    },
    "section4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen Sie uns Ihre Fragen!"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie sich mit bestimmten Fragen oder Punkten an uns wenden möchten, senden Sie uns bitte eine E-Mail an"])}
    }
  },
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])}
}