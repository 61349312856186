export const localeFilesMixin = {
    computed: {
        FILES() {
            console.log("localizad files", this.$root.$i18n.locale, FILES)
            const files = FILES[this.$root.$i18n.locale] || FILES['en'];

            return Object.fromEntries(Object.entries(files).map(([key, file]) => [key, `${files.prefix}${file}`]));
        },
    },

    methods: {
        findFileInLocale(url, targetLocale) {
            const enUri = url.replace(FILES.en.prefix, '')
            const deUri = url.replace(FILES.en.prefix, '')

            const keyFile = Object.entries(FILES.en).find(([key, file]) => file === enUri) || Object.entries(FILES.de).find(([key, file]) => file === deUri)

            if (keyFile) {
                const key = keyFile[0];
                return FILES[targetLocale].prefix + FILES[targetLocale][key];
            }

            return url;
        },

        localizedImage(img) {
            return IMAGES[this.$root.$i18n.locale][img] || IMAGES['en'][img] || img;
        }
    }
}

const IMAGES = {
    en: {},
    de: {
        'img/coating.svg': 'img/de/grafika_beschichten_nem_page 9.svg',
        'img/milling.svg': 'img/de/grafika_frasen_nem_page 9.svg',
        'img/couple.png': 'img/de/grafika_nem_page 8.png',
        'img/GIF_3.gif': 'img/de/spread-the-word_german-version.gif',
        'img/sintering.svg': 'img/de/grafika_sintern_nem_page 9.svg',
    }
}

const FILES: { [key: string]: FilesInterface } = {
    en: {
        prefix: 'https://secreets.s3.eu-central-1.amazonaws.com/en/',
        video: 'SecREEts_screen+record.mp4',
        audio_welcome: 'audio/STEP+1_WELCOME_Jill_VCL-2.mp3',
        audio_areyoufamiliar: 'audio/STEP+2/STEP+2a_Are+you+familiar_Jill_VCL.wav',
        audio_areyoufamiliar_yes: 'audio/STEP+2/STEP+2b_Great!+Some+of+them..._Jill_VCL.wav',
        audio_areyoufamiliar_no: 'audio/STEP+2/STEP+2c_Actually%2C+not+many+people..._Jill_VCL.wav',
        audio_devices: 'audio/STEP+2/STEP+2d_Can+you+guess..._Jill_VCL.wav',
        audio_devices_selected: 'audio/STEP+2/STEP+2e_That\'s+correct!+All+the+products+you+selected....wav',
        audio_map: 'audio/STEP+2/Step+2f_Map_+can+you+guess..._Jill_VCL.wav',
        audio_map_correct: 'audio/STEP+2/STEP+2g_That\'s+correct_Voicover_Jill_VCL.wav',
        audio_map_wrong: 'audio/STEP+2/STEP+2h_That\'s+incorrect.wav',
        audio_alternative_supply: 'audio/STEP+3/STEP+3a+Several+countries..._Jill_VCL.wav',
        audio_get_smart: 'audio/STEP+3/STEP+3b_That\'s+a+smart+choice..._Jill_VCL.wav',
        audio_get_smart_didyouknow: 'audio/STEP+3/STEP+3c_Did+you+know_Jill_VCL.wav',
        audio_get_wise: 'audio/STEP+3/STEP+3d_Wise+decision!..._Jill_VCL.wav',
        audio_get_wise_more: 'audio/STEP+3/STEP+3e_Furthermore.._Jill_VCL.wav',
        audio_get_wise_didyouknow: 'audio/STEP+3/STEP+3f_In+Susmagpro..._Jill_VCL.wav',

        audio_structure: 'audio/STEP+3/STEP+3g_Now+we+have+our+REE+to+use%2C+great!_Jill_VCL.wav',
        audio_magnet_process: 'audio/STEP+3/step3h-finalsteps-jill-vcl_zP4cRUoo.wav',
        audio_close_loop: 'audio/STEP+3/STEP+3j_Now%2C+your+fridge+or+your+smartphone+breaks+down+_Jill_VCL.wav',

        audio_chal_intro: 'audio/STEP+4/STEP+4a_Current+rare+earth+....wav',
        audio_chal_mining1: 'audio/STEP+4/STEP+4b_Current+mining+solutions..._Jill_VCL.wav',
        audio_chal_mining1_details: 'audio/STEP+4/STEP+4c_Both+projects+make+use+of+secondary+sources..._Jill_VCL.wav',
        audio_chal_recycling2: 'audio/STEP+4/STEP+4d_Currently%2C+the+recycling+rate..._Jill_VCL.wav',
        audio_chal_recycling2_details: 'audio/STEP+4/STEP+4e_Both+projects+develop+solutions+for+re-using+and+transforming+some+waste_Jill_VCL.wav',
        audio_chal_demand3: 'audio/STEP+4/STEP+4f_With+increasing+digitalisation+and+the+green+energy+transition_Jill_VCL.wav',
        audio_chal_demand3_details: 'audio/STEP+4/STEP+4g_Both+projects+use+alternative+supply+sources+and+create+production+_Jill_VCL.wav',

        audio_almostend: 'audio/STEP+5_COMPLETE.wav',
        audio_whatcanyou: 'audio/STEP+6/Step+6_revised.wav',
    },

    de: {
        prefix: 'https://secreets.s3.eu-central-1.amazonaws.com/de/',
        video: 'SecREEts_German_v2_screen+recording.mp4',
        audio_welcome: 'audio/01+Welcome.wav',
        audio_areyoufamiliar: 'audio/02+Do+you+know+REE.wav',
        audio_areyoufamiliar_yes: 'audio/02+Do+you+know+REE+-+YES.wav',
        audio_areyoufamiliar_no: 'audio/02+Do+you+know+REE+-+NO.wav',
        audio_devices: 'audio/03+Can+you+guess.wav',
        audio_devices_selected: 'audio/04+That\'s+correct.wav',

        audio_map: 'audio/06+Who+is+dominating.wav',
        audio_map_correct: 'audio/07+China+I.wav',
        audio_map_wrong: 'audio/07+China+II.wav',

        audio_alternative_supply: 'audio/08+Several+countries.wav',

        audio_get_smart: 'audio/09+Option+1.wav',
        audio_get_smart_didyouknow: 'audio/09+Option+1+right.wav',
        audio_get_wise: 'audio/10+Option+2.wav',
        audio_get_wise_more: 'audio/10+Option+2+More.wav',
        audio_get_wise_didyouknow: 'audio/10+Option+2+Right.wav',

        audio_structure: 'audio/11+Now+we+have+REE+ready+to+use.wav',
        audio_magnet_process: 'audio/12+Letzter+Schritt.wav',
        audio_close_loop: 'audio/13+Broken+fridge.wav',

        audio_chal_intro: 'audio/14+REE+supply.wav',
        audio_chal_mining1: 'audio/15+Mining+and+processing.wav',
        audio_chal_mining1_details: 'audio/16+Secreets+and+Susmagpro+solutions.wav',
        audio_chal_recycling2: 'audio/17+Rare+earth+recycling.wav',
        audio_chal_recycling2_details: 'audio/18+Secreets+and+Susmagpro+solutions+II.wav',
        audio_chal_demand3: 'audio/19+High+demand.wav',
        audio_chal_demand3_details: 'audio/20+Secreets+and+Susmagpro+solutions+III.wav',

        audio_almostend: 'audio/21+Almost+at+the+end.wav',
        audio_whatcanyou: 'audio/22+What+can+you+do.wav',
    }
}

interface FilesInterface {
    prefix: string,
    video: string,
    audio_welcome: string,
    audio_areyoufamiliar: string,
    audio_areyoufamiliar_yes: string,
    audio_areyoufamiliar_no: string,
    audio_devices: string,
    audio_devices_selected: string,
    audio_map: string,
    audio_map_correct: string,
    audio_map_wrong: string,
    audio_alternative_supply: string,
    audio_get_smart: string,
    audio_get_smart_didyouknow: string,
    audio_get_wise: string,
    audio_get_wise_more: string,
    audio_get_wise_didyouknow: string,
    audio_structure: string,
    audio_close_loop: string,

    audio_chal_intro: string,
    audio_magnet_process: string,
    audio_chal_mining1: string,
    audio_chal_mining1_details: string,
    audio_chal_recycling2: string,
    audio_chal_recycling2_details: string,
    audio_chal_demand3: string,
    audio_chal_demand3_details: string,

    audio_almostend: string,
    audio_whatcanyou: string,
}
