
import {defineComponent} from 'vue';
import {ScreensEnum} from "@/Screens.enum";
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import Card from "@/components/elements/Card.vue";

export default defineComponent({
  name: 'PeriodicScreen',
  components: {
    ScreenWrapper,
    Card,
  },
  props: {
    goTo: Function,
    msg: String,
  },
  data() {
    return {
      screen: ScreensEnum.VIDEO,
    };
  },
  mounted() {
    this.$refs.video?.play();
  },
  beforeUnmount() {
    this.$refs.video?.pause();
    this.$refs.video.currentTime = 0;
  },
  methods: {
    next() {
      this.$refs.video?.pause();
      this.$refs.video.currentTime = 0;
      this.goTo(ScreensEnum.MAP)
    },
    playVideo() {
      this.$refs.video?.play();
    }
  }
});
