import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "xl:container mx-auto font-roboto text-white relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransitionWrapper = _resolveComponent("TransitionWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.screens, ({key, screen}) => {
      return (_openBlock(), _createBlock(_component_TransitionWrapper, {
        key: screen,
        index: key,
        screens: _ctx.screens,
        "current-screen": _ctx.currentScreen,
        back: _ctx.isGoingBack
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(screen), _mergeProps({goTo: _ctx.goTo}, { screen: key }), null, 16, ["screen"]))
          ], 1024))
        ]),
        _: 2
      }, 1032, ["index", "screens", "current-screen", "back"]))
    }), 128))
  ]))
}