
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import ReadMore from "@/components/elements/ReadMore.vue";
import Card from "@/components/elements/Card.vue";
import {ScreensEnum} from "@/Screens.enum";

export default defineComponent({
  name: 'ChallengesScreen',
  components: {
    ReadMore,
    ScreenWrapper,
    Card
  },
  props: {
    msg: String,
    goTo: Function
  },

  data() {
    return {
      option: null,
      screen: ScreensEnum.CHALLENGES,
    }
  },

  mounted() {
    setTimeout(() => {
      this.$root.setupAndPlayIfOn(this.FILES.audio_chal_intro)
    }, 1000)
  },


  methods: {
    next() {
      this.goTo(ScreensEnum.FEEDBACK)
    },
    toggleOption(selected) {

      this.option = selected

      switch (selected) {
        case 1:
          this.$root.setupAndPlayIfOn(this.FILES.audio_chal_mining1)
          break;
        case 2:
          this.$root.setupAndPlayIfOn(this.FILES.audio_chal_recycling2)
          break;
        case 3:
          this.$root.setupAndPlayIfOn(this.FILES.audio_chal_demand3)
          break;
        default:
          this.$root.stopAudio();
      }

    },
    closeCard() {
      this.option = null;
    }
  }
});
