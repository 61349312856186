
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import Card from "@/components/elements/Card.vue";
import {ScreensEnum} from "@/Screens.enum";

export default defineComponent({
  name: 'SelectDevicesScreen',
  components: {
    ScreenWrapper, Card
  },
  props: {
    msg: String,
    goTo: Function
  },
  data() {
    return {
      screen: ScreensEnum.DEVICES,
      selected: {},
      ok: false
    }
  },
  computed: {
    anySelected() {
      return Object.values(this.selected).filter(s => s).length > 0
    }
  },

  mounted() {
    setTimeout(() => {
      this.$root.setupAndPlayIfOn(this.FILES.audio_devices)
    }, 1000)
  },

  methods: {
    next() {
      this.goTo(ScreensEnum.VIDEO)
    },

    select(item) {
      this.selected[item] = !this.selected[item] || false
    },

    checkAnswers() {
      if (Object.keys(this.selected).length > 0) {
        this.ok = true
        this.$root.setupAndPlayIfOn(this.FILES.audio_devices_selected)
      }
    }
  }
});
