
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'Card',
  emits: ['closed'],
  props: {
    title: String,
    titleClass: String,
    wrapperClass: String,
    close: Boolean,
  },
  methods: {
    closeCard() {
      this.$emit('closed')
    }
  }
});
