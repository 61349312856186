import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54a252ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "absolute w-full",
  ref: "TransitionWrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    name: _ctx.back ? 'screen-back' : 'screen',
    duration: 2000,
    appear: ""
  }, {
    default: _withCtx(() => [
      (_ctx.isActive)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 512))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["name"]))
}